import axios from "axios";
import handleError from "../common/handle-error";
import {useAuth} from "../context/auth-context";

export const isBrowser = () => typeof window !== "undefined";

const loopbackUrl = process.env.LOOPBACK_API_URL;
let token = '';
if (isBrowser()) {
    token = window.sessionStorage.getItem("token") ?? window.localStorage.getItem("token") ?? '';
}

export const searching = async (keyword, page=1) => {
    let result = [];
    try {
        const response = await axios.get(`${loopbackUrl}/content/search/hu/${keyword}/${page}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const addToCart = async (uid, dealId, item) => {
    let result = { success: false, message: "" }
    try {
        const response = await axios.post(`${loopbackUrl}/shop/add-to-cart/${uid}/${dealId}`, item, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    if (!result.success) {
        console.error("result", result)
    }
    return result
}

export const getCart = async (uid) => {
    let result = [];
    try {
        const response = await axios.get(`${loopbackUrl}/shop/cart/${uid}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const getCartDeals = async (ids) => {
    let result = [];
    try {
        const response = await axios.post(`${loopbackUrl}/shop/cart-deals`, {where: {id: {inq: ids}}}, {
            headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const deleteFromCart = async (uid, id) => {
    let result = [];
    try {
        const response = await axios.delete(`${loopbackUrl}/shop/cart/${uid}/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const handleBuy = async (cart, payment, user) => {
    let result = [];
    try {
        const data = {
            cart,
            payment,
        }
        let config = {};
        let url = `${loopbackUrl}/shop/buy`;
        if (user?.id && token) {
            url = `${loopbackUrl}/shop/buy/me/${user.id}`;
            config = {
                headers: { Authorization: `Bearer ${token}` },
            };
        } else {
            data.guest = user;
        }
        const response = await axios.post(url, data, config)
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const simplepayBack = async (r, s) => {
    let result = [];
    try {
        let config = {};
        let url = `${loopbackUrl}/payment/simplepay/back?r=${r}&s=${s}`;
        const response = await axios.get(url, config)
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const paypalSuccess = async (paymentId, token, payerId) => {
    let result = [];
    try {
        let config = {};
        let url = `${loopbackUrl}/payment/paypal/success?paymentId=${paymentId}&token=${token}&payerId=${payerId}`;
        const response = await axios.get(url, config)
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const paypalCancel = async (token) => {
    let result = [];
    try {
        let config = {};
        let url = `${loopbackUrl}/payment/paypal/cancel?token=${token}`;
        const response = await axios.get(url, config)
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const paymentCheck = async (paymentCode) => {
    let result = [];
    try {
        let config = {};
        let url = `${loopbackUrl}/payment/check/${paymentCode}`;
        const response = await axios.get(url, config)
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const getBought = async () => {
    let result = [];
    try {
        const response = await axios.get(`${loopbackUrl}/shop/bought`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const getBoughtCoupons = async (buyerId, verification) => {
    let result = null;
    try {
        const response = await axios.get(`${loopbackUrl}/shop/bought/coupons/${buyerId}/${verification}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const getCoupon = async (coupon, verification) => {
    let result = null;
    try {
        const response = await axios.get(`${loopbackUrl}/shop/coupon/${coupon}/${verification}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}

export const subscribeNewsletter = async (email) => {
    let result = null;
    try {
        const response = await axios.get(`${loopbackUrl}/users/newsletter/${email}`);
        if (response.data) {
            result = response.data;
        }
    } catch (err) {
        handleError(err);
    }
    return result
}
