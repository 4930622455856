exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forgotten-js": () => import("./../../../src/pages/forgotten.js" /* webpackChunkName: "component---src-pages-forgotten-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-paypal-cancel-js": () => import("./../../../src/pages/payment/paypal/cancel.js" /* webpackChunkName: "component---src-pages-payment-paypal-cancel-js" */),
  "component---src-pages-payment-paypal-success-js": () => import("./../../../src/pages/payment/paypal/success.js" /* webpackChunkName: "component---src-pages-payment-paypal-success-js" */),
  "component---src-pages-payment-simplepay-js": () => import("./../../../src/pages/payment/simplepay.js" /* webpackChunkName: "component---src-pages-payment-simplepay-js" */),
  "component---src-pages-profile-cancel-js": () => import("./../../../src/pages/profile/cancel.js" /* webpackChunkName: "component---src-pages-profile-cancel-js" */),
  "component---src-pages-profile-cart-js": () => import("./../../../src/pages/profile/cart.js" /* webpackChunkName: "component---src-pages-profile-cart-js" */),
  "component---src-pages-profile-change-password-js": () => import("./../../../src/pages/profile/change-password.js" /* webpackChunkName: "component---src-pages-profile-change-password-js" */),
  "component---src-pages-profile-confirm-js": () => import("./../../../src/pages/profile/confirm.js" /* webpackChunkName: "component---src-pages-profile-confirm-js" */),
  "component---src-pages-profile-coupon-js": () => import("./../../../src/pages/profile/coupon.js" /* webpackChunkName: "component---src-pages-profile-coupon-js" */),
  "component---src-pages-profile-coupons-js": () => import("./../../../src/pages/profile/coupons.js" /* webpackChunkName: "component---src-pages-profile-coupons-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-deal-js": () => import("./../../../src/templates/deal.js" /* webpackChunkName: "component---src-templates-deal-js" */),
  "component---src-templates-deal-list-js": () => import("./../../../src/templates/deal-list.js" /* webpackChunkName: "component---src-templates-deal-list-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */)
}

